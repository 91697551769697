@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.App-header {
  /* background-color: rgb(253, 240, 230); */
  font-size: calc(10px + 2vmin);
}

.bot-message {
  background-color: rgb(253, 240, 230);
}

.human-message {
  background-color: rgb(240, 142, 128);
}

.send-message {
  background-color: rgb(240, 142, 128);
  &:hover {
    background-color: rgb(247, 112, 91);
  }
}

.libre-franklin-300 {
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.libre-franklin-600 {
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.libre-franklin-900 {
  font-family: "Libre Franklin", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}