/* Chat UI Styling */

/* Desert Sunset Color Palette */
:root {
  --sunset-orange: #ff7e5f;
  --sunset-pink: #feb47b;
  --sunset-yellow: #ffcb8e;
  --sunset-purple: #845ec2;
  --sunset-deep: #d65db1;
  --sand-light: #f9f4e8;
  --sand-medium: #e6dfd1;
  --sand-dark: #c2b8a3;
}

/* Message animations */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease-out forwards;
}

/* Fade-out effect for scrolling messages - both top and bottom */
.chat-container {
  width: 100%;
  mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.5) 5%,
    rgba(0, 0, 0, 1) 15%,
    rgba(0, 0, 0, 1) 85%,
    rgba(0, 0, 0, 0.5) 95%,
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.5) 5%,
    rgba(0, 0, 0, 1) 15%,
    rgba(0, 0, 0, 1) 85%,
    rgba(0, 0, 0, 0.5) 95%,
    transparent 100%
  );
  padding: 60px 0; /* Add padding to ensure the fade effect is visible */
  margin: -40px 0; /* Offset the padding */
  min-height: 100%;
}

/* Additional fade effect for older messages */
.message-fade {
  transition: opacity 0.3s ease;
}

.message-fade:not(:nth-last-child(-n+3)) {
  opacity: 0.85;
}

.message-fade:not(:nth-last-child(-n+5)) {
  opacity: 0.7;
}

/* Typing indicator animation */
.typing-indicator {
  display: flex;
  align-items: center;
}

.typing-indicator span {
  height: 8px;
  width: 8px;
  margin: 0 1px;
  background-color: var(--sunset-orange);
  border-radius: 50%;
  display: inline-block;
  opacity: 0.4;
}

.typing-indicator span:nth-child(1) {
  animation: pulse 1s infinite 0.1s;
}

.typing-indicator span:nth-child(2) {
  animation: pulse 1s infinite 0.3s;
}

.typing-indicator span:nth-child(3) {
  animation: pulse 1s infinite 0.5s;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 0.4;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: var(--sand-light);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--sand-dark);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--sunset-pink);
}

/* Message bubble styles */
.bot-message {
  background-color: white;
  color: #1f2937;
  border-top-left-radius: 0;
  border-color: var(--sand-medium);
  position: relative;
}

.bot-message::before {
  content: '';
  position: absolute;
  left: -8px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 8px solid white;
  border-left: 8px solid transparent;
}

.human-message {
  background: linear-gradient(135deg, var(--sunset-orange), var(--sunset-pink));
  color: white;
  border-top-right-radius: 0;
  position: relative;
}

.human-message::before {
  content: '';
  position: absolute;
  right: -8px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 8px solid var(--sunset-orange);
  border-right: 8px solid transparent;
}

/* Send button styling */
.send-message {
  background: linear-gradient(135deg, var(--sunset-orange), var(--sunset-pink));
}

.send-message:hover {
  background: linear-gradient(135deg, var(--sunset-deep), var(--sunset-orange));
}

/* Input focus effect */
input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 126, 95, 0.2);
}

/* Message container */
.message-container {
  transition: all 0.3s ease;
  margin-bottom: 12px;
}

/* Chat background */
.chat-bg {
  background-color: var(--sand-light);
}

/* Chat scroll container */
.chat-scroll-container {
  position: relative;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .max-w-2xl {
    max-width: 100%;
  }
}
